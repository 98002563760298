import React, { useEffect } from 'react';
import { message, Result, Typography } from 'antd';
import DefaultLayout from '../components/layouts/default.layout';
import { useSelector } from 'react-redux';
import { IState } from '../state/types';
import { isWorkClassUrl } from '../common/utils';

const CompletePage: React.FC = () => {
  const { source, redirect, country } = useSelector((state: IState) => state);

  useEffect(() => {
    // if redirect is available redirect user back to uri
    if (redirect && isWorkClassUrl(redirect)) {
      message.success({ content: "Please wait, uou'll be redirected back" });
      setTimeout(() => {
        window.location.href = redirect;
      }, 2000);
    }
  }, []);

  const formatReturnString = () => {
    if (source === 'website') {
      return (
        <div>
          <p>
            We will email you your resume and set your WorkClass resume to the
            newly generated to this one
          </p>
          <a href={`https://workclass.co/${country}`}>You can return to WorkClass.co now</a>
        </div>
      );
    } else return <p>You can return to {source} now</p>;
  };

  return (
    <DefaultLayout>
      <div style={s.spacer}>
        <Result
          status="success"
          title="All Done"
          subTitle={formatReturnString()}
        />
      </div>
    </DefaultLayout>
  );
};

export default CompletePage;

const s: Stylesheet = {
  spacer: {
    textAlign: 'center',
    marginTop: 50
  },
  btn: {
    width: 200,
    height: 50
  },
  naviBtn: {
    minHeight: 40,
    minWidth: 120
  }
};
